<template>
    <div id="customisation-page">
        <toolbar-back-button-component :title="$t('custom_page.customisation')" />

        <v-list class="none-background pt-0">
            <v-list-item-group>
                <v-list-item :to="'/parameter/customisation/navigation'" class="py-1" link>
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-bold">{{ $t('custom_page.title') }}</v-list-item-title>
                        <span class="font-14 font-color-light">{{ $t('custom_page.available_modules') }}</span>
                    </v-list-item-content>
                    <v-icon>mdi-chevron-right</v-icon>
                </v-list-item>

                <v-divider class="mr-4 ml-4" color="white" />

                <v-list-item link @click="appearance_sheet = true">
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-bold mb-2">
                            {{ $t('custom_page.appearance') }}
                        </v-list-item-title>
                        <span class="font-14 font-color-light">{{ $t('custom_page.appearance_preference') }}</span>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>

        <v-bottom-sheet v-model="appearance_sheet">
            <v-sheet v-touch:swipe.bottom="swipeBottomHandler" class="pa-4">
                <p class="text-uppercase font-20 mb-0">{{ $t('custom_page.appearance') }}</p>
                <p class="font-14">{{ $t('custom_page.choose_appearance_preference') }}</p>

                <v-radio-group v-model="radio_appearance_value">
                    <v-radio
                        v-for="appearance in appearances"
                        :key="appearance.id"
                        :label="appearance.label"
                        :value="appearance.id"
                        class="pb-2"
                        @click="updateAppearance()"
                    />
                </v-radio-group>
            </v-sheet>
        </v-bottom-sheet>
    </div>
</template>

<script>
import ToolbarBackButtonComponent from '@/components/Global/ToolbarBackButtonComponent'

export default {
    name: 'CustomisationPage',
    components: { ToolbarBackButtonComponent },
    created() {
        if (this.$vuetify.theme.dark) {
            this.radio_appearance_value = 2
        }
    },
    data() {
        return {
            appearance_sheet: false,
            radio_appearance_value: 1,
            appearances: [
                {
                    id: 1,
                    label: this.$t('custom_page.light_mode'),
                },
                {
                    id: 2,
                    label: this.$t('custom_page.dark_mode'),
                },
            ],
        }
    },
    methods: {
        updateAppearance() {
            this.appearance_sheet = false
            if (this.radio_appearance_value === 1) {
                this.$vuetify.theme.light = true
                this.$vuetify.theme.dark = false
            } else {
                this.$vuetify.theme.light = false
                this.$vuetify.theme.dark = true
            }
        },

        swipeBottomHandler() {
            this.appearance_sheet = false
        },
    },
}
</script>
